/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    ul: "ul",
    li: "li",
    p: "p",
    h3: "h3",
    strong: "strong",
    em: "em"
  }, _provideComponents(), props.components), {Boss, Skill, Trait, Warning, Item} = _components;
  if (!Boss) _missingMdxReference("Boss", true);
  if (!Item) _missingMdxReference("Item", true);
  if (!Skill) _missingMdxReference("Skill", true);
  if (!Trait) _missingMdxReference("Trait", true);
  if (!Warning) _missingMdxReference("Warning", true);
  return React.createElement(React.Fragment, null, React.createElement(Boss, {
    name: "LightAi",
    foodId: "92456",
    utilityId: "48917",
    healId: "12489",
    utility1Id: "40498",
    utility2Id: "12498",
    utility3Id: "12537",
    eliteId: "45717",
    weapon1MainAffix: "Viper",
    weapon1MainType: "Short Bow",
    weapon1MainSigil1: "Bursting",
    weapon1MainSigil2: "Earth",
    weapon1MainInfusion1Id: "37130",
    weapon2OffAffix: "Viper",
    weapon2OffType: "Torch",
    weapon2OffSigil: "Earth",
    weapon2OffInfusionId: "37130",
    weapon2MainAffix: "Viper",
    weapon2MainType: "Dagger",
    weapon2MainSigil1: "Bursting",
    weapon2MainInfusion1Id: "37130",
    foodId: "92456",
    utilityId: "48917",
    healId: "12489",
    utility1Id: "40498",
    utility2Id: "12498",
    utility3Id: "12537",
    eliteId: "45717",
    weapon1MainInfusion1Id: "37130",
    weapon2OffInfusionId: "37130",
    weapon2MainInfusion1Id: "37130",
    weapon1MainId: "74569",
    weapon1MainSigil1Id: "44944",
    weapon1MainSigil2Id: "24560",
    weapon2MainId: "74179",
    weapon2MainSigil1Id: "44944",
    weapon2OffId: "76271",
    weapon2OffSigilId: "24560"
  }, React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Pet: ", React.createElement(Skill, {
    id: "46432",
    disableText: true,
    id: "46432"
  }), " Warthog"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Take ", React.createElement(Trait, {
    id: "2128",
    id: "2128"
  }), "."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(Trait, {
    id: "1912",
    id: "1912"
  }), "."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(Skill, {
    name: "Quick shot",
    id: "12517"
  }), " and ", React.createElement(Skill, {
    name: "Instinctive Engage",
    id: "46123"
  }), " are great movement abilities for following Ai when dashing and also to dodge suicidal teammates."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(Skill, {
    name: "Healing Spring",
    id: "12489"
  }), " is a strong condi cleanse and will really help your group, especially if your party likes standing in every AoE at the sides."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Remember to dodge as often as you can to keep ", React.createElement(Trait, {
    name: "Light on your Feet",
    id: "1912"
  }), " active."), "\n"), "\n")), "\n", React.createElement(_components.h3, null, React.createElement(_components.strong, null, "Precast")), "\n", React.createElement(Warning, null, React.createElement(_components.p, null, "Boons get removed upon entering combat here, so stacking them is pointless. Effects will stay though so take advantage of that and precast what you can.")), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Optionally before pre-stacking you can get into combat by jumping off the side of the map. This allows you to pre-stack on weapon swap sigils such as ", React.createElement(Item, {
    id: "24609",
    id: "24609"
  }), " and ", React.createElement(Item, {
    id: "24599",
    id: "24599"
  }), ". While in combat you can also precast ", React.createElement(Trait, {
    name: "Poison MAster",
    id: "1701"
  }), " by using a ", React.createElement(Skill, {
    id: "42944",
    id: "42944"
  }), " ability."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(Skill, {
    name: "Sharpen Spines",
    id: "45479"
  }), " while merged with Bristleback then swap to your desired pet and enter ", React.createElement(Skill, {
    id: "42944",
    id: "42944"
  }), " (If you are staying with Bristleback you can cast ", React.createElement(Skill, {
    name: "Sharpen Spines",
    id: "45479"
  }), " a second time after taking the ", React.createElement(_components.em, null, "Mistlock Singularity"), ")"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "With ", React.createElement(Trait, {
    name: "Light on your Feet",
    id: "1912"
  }), " selected, use ", React.createElement(Skill, {
    name: "Quick Shot",
    id: "12517"
  }), " and dodge twice."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "As you are dodging cast ", React.createElement(Skill, {
    name: "Vulture stance",
    id: "40498"
  }), ", ", React.createElement(Skill, {
    name: "sharpening stone",
    id: "12537"
  }), ", ", React.createElement(Skill, {
    name: "one wolf pack",
    id: "45717"
  }), " and ", React.createElement(Skill, {
    name: "moa stance",
    id: "45970"
  })), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Cast ", React.createElement(Skill, {
    name: "Double Arc",
    id: "43536"
  })), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Take mistlock ", React.createElement(_components.em, null, "Mistlock Singularity"), " and swap any skills back you have changed."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Cast ", React.createElement(Skill, {
    name: "Double Arc",
    id: "43536"
  }), " again"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "As you walk into the arena make sure to stack ", React.createElement(Trait, {
    id: "1912",
    id: "1912"
  }), " for some extra initial damage."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Cast ", React.createElement(Skill, {
    name: "Sun Spirit",
    id: "12498"
  }), " so it spawns towards the middle of the arena."), "\n"), "\n"), "\n", React.createElement(Warning, null, React.createElement(_components.p, null, "In pug groups it is recommended to cc Ai in the middle of the arena. This will give you the maximum amount of time to DPS Ai, which will allow most groups to skip most of the split phases. On SLB you don't want to delay your ", React.createElement(Skill, {
    name: "Concussion Shot",
    id: "12508"
  }), " as it will stop you from swapping to Dagger/Torch and dealing as much damage, but you should delay your ", React.createElement(Skill, {
    id: "46432",
    id: "46432"
  }), " if you need to. Ideally Ai's CC bar should break as the Red Agony field spawns beneath her.")), "\n", React.createElement(_components.h3, null, React.createElement(_components.em, null, "Air Phase")), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "As you get into combat cast ", React.createElement(Skill, {
    name: "Vulture Stance",
    id: "40498"
  }), " and ", React.createElement(Skill, {
    name: "Sharpening Stone",
    id: "12537"
  }), ". Use ", React.createElement(Skill, {
    name: "Concussion Shot",
    id: "12508"
  }), ", ", React.createElement(Skill, {
    name: "Crippling Shot",
    id: "12507"
  }), ", ", React.createElement(Skill, {
    name: "Poison Volley",
    id: "12468"
  }), " and follow Ai to the side."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "As you reach the side call ", React.createElement(Skill, {
    name: "Sun Spirit",
    id: "12498"
  }), " to you with ", React.createElement(Skill, {
    name: "Solar Flare",
    id: "12597"
  }), "."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Cast ", React.createElement(Skill, {
    name: "one wolf pack",
    id: "45717"
  }), " and swap to Dagger/Torch and use ", React.createElement(Skill, {
    name: "Bonfire",
    id: "12504"
  }), ", ", React.createElement(Skill, {
    name: "Double Arc",
    id: "43536"
  }), ", ", React.createElement(Skill, {
    name: "Throw Torch",
    id: "12635"
  }), " and your ", React.createElement(Skill, {
    id: "42944",
    id: "42944"
  }), " skills."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Remember to dodge as often as you can to keep ", React.createElement(Trait, {
    name: "Light on your Feet",
    id: "1912"
  }), " active and use ", React.createElement(Skill, {
    name: "Double Arc",
    id: "43536"
  }), " and ", React.createElement(Skill, {
    name: "Throw Torch",
    id: "12635"
  }), " as they come off cool-down."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Swap back to Shortbow and follow Ai to the middle of the arena and as the expanding AoE's appear use ", React.createElement(Skill, {
    name: "Quick Shot",
    id: "12517"
  }), " to dodge back so you don't get demerged,"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "After the split mechanic call ", React.createElement(Skill, {
    name: "Sun Spirit",
    id: "12498"
  }), " mid with ", React.createElement(Skill, {
    name: "Solar Flare",
    id: "12597"
  }), ". As the CC bar comes up use ", React.createElement(Skill, {
    name: "Concussion Shot",
    id: "12508"
  }), " and ", React.createElement(Skill, {
    id: "46432",
    id: "46432"
  }), ", then swap to Dagger/Torch continuing with your rotation until Ai phases (Hopefully before the air split phase happens)."), "\n"), "\n"), "\n", React.createElement(_components.h3, null, React.createElement(_components.em, null, "Fire Phase")), "\n", React.createElement(Warning, null, React.createElement(_components.p, null, "Ideally your group will use a Xera portal to get back to the middle to DPS Ai quickly. Make sure you communicate with your team before the fight to work out if you or someone else will drop one.")), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Start this phase on Dagger/Torch"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "As the split phase ends take a Xera portal back to the middle casting ", React.createElement(Skill, {
    name: "Sun Spirit",
    id: "12498"
  }), " as you go through."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Once in the middle use ", React.createElement(Skill, {
    name: "One Wolf Pack",
    id: "45717"
  }), ", ", React.createElement(Skill, {
    name: "Vulture Stance",
    id: "40498"
  }), " and ", React.createElement(Skill, {
    name: "Sharpening Stone",
    id: "12537"
  }), "."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Use ", React.createElement(Skill, {
    name: "Bonfire",
    id: "12504"
  }), ", ", React.createElement(Skill, {
    name: "Double Arc",
    id: "43536"
  }), ", ", React.createElement(Skill, {
    name: "Throw Torch",
    id: "12635"
  }), " and swap to Shortbow."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Use ", React.createElement(Skill, {
    name: "Concussion Shot",
    id: "12508"
  }), ", ", React.createElement(Skill, {
    name: "Crippling Shot",
    id: "12507"
  }), " and ", React.createElement(Skill, {
    name: "Poison Volley",
    id: "12468"
  }), " and your ", React.createElement(Skill, {
    id: "42944",
    id: "42944"
  }), " skills.Keep DPSing until you need to head to the safe spot by the Meteor. Once it is safe to leave follow Ai to the side, calling ", React.createElement(Skill, {
    name: "Sun Spirit",
    id: "12498"
  }), " to you with ", React.createElement(Skill, {
    name: "Solar Flare",
    id: "12597"
  }), "."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "As you reach the side use ", React.createElement(Skill, {
    name: "Concussion Shot",
    id: "12508"
  }), ", ", React.createElement(Skill, {
    name: "Crippling Shot",
    id: "12507"
  }), " and ", React.createElement(Skill, {
    name: "Poison Volley",
    id: "12468"
  }), " and swap to Dagger/Torch and continue with your rotation."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Follow Ai to the middle of the arena and as the expanding AoE's appear swap to Shortbow and use ", React.createElement(Skill, {
    name: "Quick Shot",
    id: "12517"
  }), " to dodge back so you don't get demerged,"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "After the split mechanic call ", React.createElement(Skill, {
    name: "Sun Spirit",
    id: "12498"
  }), " mid with ", React.createElement(Skill, {
    name: "Solar Flare",
    id: "12597"
  }), ". As the CC bar comes up use ", React.createElement(Skill, {
    name: "Concussion Shot",
    id: "12508"
  }), " and ", React.createElement(Skill, {
    id: "46432",
    id: "46432"
  }), ", then swap to Dagger/Torch continuing with your rotation until Ai phases (Hopefully before the meteors fall)."), "\n"), "\n"), "\n", React.createElement(_components.h3, null, React.createElement(_components.em, null, "Water Phase")), "\n", React.createElement(Warning, null, React.createElement(_components.p, null, "There is not much you can do in the split phase here, so just make sure to keep an eye on tethers and to give/take them if you or someone else is close to 9 stacks. If your ", React.createElement(Skill, {
    name: "Sun Spirit",
    id: "12498"
  }), " has died remember to summon it towards the end of the split phase. It is also beneficial to be in the middle of the arena her so you have less distance to walk when Ai dashes to the side again.")), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Summon ", React.createElement(Skill, {
    name: "Sun Spirit",
    id: "12498"
  }), " if it has died shortly before ai becomes vulnerable,"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Once vulnerable cast ", React.createElement(Skill, {
    name: "Vulture Stance",
    id: "40498"
  }), " and ", React.createElement(Skill, {
    name: "Sharpening Stone",
    id: "12537"
  }), ". Use ", React.createElement(Skill, {
    name: "Concussion Shot",
    id: "12508"
  }), ", ", React.createElement(Skill, {
    name: "Crippling Shot",
    id: "12507"
  }), ", ", React.createElement(Skill, {
    name: "Poison Volley",
    id: "12468"
  }), " and follow Ai to the side."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "As you reach the side call ", React.createElement(Skill, {
    name: "Sun Spirit",
    id: "12498"
  }), " to you with ", React.createElement(Skill, {
    name: "Solar Flare",
    id: "12597"
  }), "."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Cast ", React.createElement(Skill, {
    name: "one wolf pack",
    id: "45717"
  }), " and swap to Dagger/Torch and use ", React.createElement(Skill, {
    name: "Bonfire",
    id: "12504"
  }), ", ", React.createElement(Skill, {
    name: "Double Arc",
    id: "43536"
  }), ", ", React.createElement(Skill, {
    name: "Throw Torch",
    id: "12635"
  }), " and your ", React.createElement(Skill, {
    id: "42944",
    id: "42944"
  }), " skills."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Remember to dodge as often as you can to keep ", React.createElement(Trait, {
    name: "Light on your Feet",
    id: "1912"
  }), " active and use ", React.createElement(Skill, {
    name: "Double Arc",
    id: "43536"
  }), " and ", React.createElement(Skill, {
    name: "Throw Torch",
    id: "12635"
  }), " as they come off cool-down."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Swap back to Shortbow and follow Ai to the middle of the arena."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "As the expanding AoE's appear use ", React.createElement(Skill, {
    name: "Quick Shot",
    id: "12517"
  }), " to dodge back so you don't get demerged, and after the split mechanic call ", React.createElement(Skill, {
    name: "Sun Spirit",
    id: "12498"
  }), " mid with ", React.createElement(Skill, {
    name: "Solar Flare",
    id: "12597"
  }), "."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "As the CC bar comes up use ", React.createElement(Skill, {
    name: "Concussion Shot",
    id: "12508"
  }), " and ", React.createElement(Skill, {
    id: "46432",
    id: "46432"
  }), ", then swap to Dagger/Torch continuing with your rotation until Ai phases (Hopefully before the water split phase happens)."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "If the water split happens, there is not much you can do since Ai will reflect all projectiles, so help your team by standing in the greens and taking tethers. Once the split phase finishes Ai should be on low health, so follow her if she dashes and continue with your rotation until she phases."), "\n"), "\n"), "\n", React.createElement(Boss, {
    name: "DarkAi",
    foodId: "92456",
    utilityId: "48917",
    healId: "12489",
    utility1Id: "40498",
    utility2Id: "12498",
    utility3Id: "12537",
    eliteId: "45717",
    weapon1MainAffix: "Viper",
    weapon1MainType: "Short Bow",
    weapon1MainSigil1: "Bursting",
    weapon1MainSigil2: "Earth",
    weapon1MainInfusion1Id: "37130",
    weapon2OffAffix: "Viper",
    weapon2OffType: "Torch",
    weapon2OffSigil: "Earth",
    weapon2OffInfusionId: "37130",
    weapon2MainAffix: "Viper",
    weapon2MainType: "Dagger",
    weapon2MainSigil1: "Bursting",
    weapon2MainInfusion1Id: "37130",
    foodId: "92456",
    utilityId: "48917",
    healId: "12489",
    utility1Id: "40498",
    utility2Id: "12498",
    utility3Id: "12537",
    eliteId: "45717",
    weapon1MainInfusion1Id: "37130",
    weapon2OffInfusionId: "37130",
    weapon2MainInfusion1Id: "37130",
    weapon1MainId: "74569",
    weapon1MainSigil1Id: "44944",
    weapon1MainSigil2Id: "24560",
    weapon2MainId: "74179",
    weapon2MainSigil1Id: "44944",
    weapon2OffId: "76271",
    weapon2OffSigilId: "24560"
  }, React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Pet: ", React.createElement(Skill, {
    id: "46432",
    disableText: true,
    id: "46432"
  }), " Warthog"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Take ", React.createElement(Trait, {
    id: "2128",
    id: "2128"
  }), "."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(Trait, {
    id: "1912",
    id: "1912"
  }), "."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(Skill, {
    name: "Quick shot",
    id: "12517"
  }), " and ", React.createElement(Skill, {
    name: "Instinctive Engage",
    id: "46123"
  }), " are great movement abilities for following Ai when dashing and also to dodge suicidal teammates."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(Skill, {
    name: "Healing Spring",
    id: "12489"
  }), " is a strong condi cleanse and will really help your group, especially if your party likes standing in every AoE at the sides."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Remember to dodge as often as you can to keep ", React.createElement(Trait, {
    name: "Light on your Feet",
    id: "1912"
  }), " active."), "\n"), "\n")), "\n", React.createElement(_components.h3, null, React.createElement(_components.strong, null, "Precast")), "\n", React.createElement(Warning, null, React.createElement(_components.p, null, "Boons get removed upon entering combat here, so stacking them is pointless. Effects will stay though so take advantage of that and precast what you can.")), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Optionally before pre-stacking you can get into combat by jumping off the side of the map. This allows you to pre-stack on weapon swap sigils such as ", React.createElement(Item, {
    id: "24609",
    id: "24609"
  }), " and ", React.createElement(Item, {
    id: "24599",
    id: "24599"
  }), ". While in combat you can also precast ", React.createElement(Trait, {
    name: "Poison MAster",
    id: "1701"
  }), " by using a ", React.createElement(Skill, {
    id: "42944",
    id: "42944"
  }), " ability."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(Skill, {
    name: "Sharpen Spines",
    id: "45479"
  }), " while merged with Bristleback then swap to your desired pet and enter ", React.createElement(Skill, {
    id: "42944",
    id: "42944"
  }), " (If you are staying with Bristleback you can cast ", React.createElement(Skill, {
    name: "Sharpen Spines",
    id: "45479"
  }), " a second time after taking the ", React.createElement(_components.em, null, "Mistlock Singularity"), ")"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "With ", React.createElement(Trait, {
    name: "Light on your Feet",
    id: "1912"
  }), " selected, use ", React.createElement(Skill, {
    name: "Quick Shot",
    id: "12517"
  }), " and dodge twice."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "As you are dodging cast ", React.createElement(Skill, {
    name: "Vulture stance",
    id: "40498"
  }), ", ", React.createElement(Skill, {
    name: "sharpening stone",
    id: "12537"
  }), ", ", React.createElement(Skill, {
    name: "one wolf pack",
    id: "45717"
  }), " and ", React.createElement(Skill, {
    name: "moa stance",
    id: "45970"
  })), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Cast ", React.createElement(Skill, {
    name: "Double Arc",
    id: "43536"
  })), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Take mistlock ", React.createElement(_components.em, null, "Mistlock Singularity"), " and swap any skills back you have changed."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Cast ", React.createElement(Skill, {
    name: "Double Arc",
    id: "43536"
  }), " again"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "As you walk into the arena make sure to stack ", React.createElement(Trait, {
    id: "1912",
    id: "1912"
  }), " for some extra initial damage."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Cast ", React.createElement(Skill, {
    name: "Sun Spirit",
    id: "12498"
  }), " so it spawns towards the middle of the arena."), "\n"), "\n"), "\n", React.createElement(Warning, null, React.createElement(_components.p, null, "In pug groups it is recommended to cc Ai after she dashes from the side on the 100%-66% phase. This will give you the maximum amount of time to DPS Ai to 66% stopping some mechanics from overlapping as you go to CC the first Sorrow. On SLB you don't want to delay your ", React.createElement(Skill, {
    name: "Concussion Shot",
    id: "12508"
  }), " as it will stop you from swapping to Dagger/Torch and dealing as much damage, but you should delay your ", React.createElement(Skill, {
    id: "46432",
    id: "46432"
  }), " if you need to. Ideally Ai's CC bar should break as the Red Agony field spawns beneath her. After 66% you should CC Ai if you can, but it is more important to make sure you have enough CC to break the Sorrows so you don't wipe.")), "\n", React.createElement(_components.h3, null, React.createElement(_components.em, null, "100% - 66%")), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "As you get into combat cast ", React.createElement(Skill, {
    name: "Vulture Stance",
    id: "40498"
  }), " and ", React.createElement(Skill, {
    name: "Sharpening Stone",
    id: "12537"
  }), ". Use ", React.createElement(Skill, {
    name: "Concussion Shot",
    id: "12508"
  }), ", ", React.createElement(Skill, {
    name: "Crippling Shot",
    id: "12507"
  }), ", ", React.createElement(Skill, {
    name: "Poison Volley",
    id: "12468"
  }), " and follow Ai to the side."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "As you reach the side call ", React.createElement(Skill, {
    name: "Sun Spirit",
    id: "12498"
  }), " to you with ", React.createElement(Skill, {
    name: "Solar Flare",
    id: "12597"
  }), "."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Cast ", React.createElement(Skill, {
    name: "one wolf pack",
    id: "45717"
  }), " and swap to Dagger/Torch and use ", React.createElement(Skill, {
    name: "Bonfire",
    id: "12504"
  }), ", ", React.createElement(Skill, {
    name: "Double Arc",
    id: "43536"
  }), ", ", React.createElement(Skill, {
    name: "Throw Torch",
    id: "12635"
  }), " and your ", React.createElement(Skill, {
    id: "42944",
    id: "42944"
  }), " skills."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Remember to dodge as often as you can to keep ", React.createElement(Trait, {
    name: "Light on your Feet",
    id: "1912"
  }), " active and use ", React.createElement(Skill, {
    name: "Double Arc",
    id: "43536"
  }), " and ", React.createElement(Skill, {
    name: "Throw Torch",
    id: "12635"
  }), " as they come off cool-down."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Swap back to Shortbow and follow Ai to her next location."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "as the expanding AoE's appear use ", React.createElement(Skill, {
    name: "Quick Shot",
    id: "12517"
  }), " to dodge back so you don't get demerged,"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "After the split mechanic call ", React.createElement(Skill, {
    name: "Sun Spirit",
    id: "12498"
  }), " to you with ", React.createElement(Skill, {
    name: "Solar Flare",
    id: "12597"
  }), ". As the CC bar comes up use ", React.createElement(Skill, {
    name: "Concussion Shot",
    id: "12508"
  }), " and ", React.createElement(Skill, {
    id: "46432",
    id: "46432"
  }), ", then swap to Dagger/Torch continuing with your rotation until Ai phases."), "\n"), "\n"), "\n", React.createElement(_components.h3, null, React.createElement(_components.em, null, "66% - 33%")), "\n", React.createElement(Warning, null, React.createElement(_components.p, null, "Your rotation from here will depend on your groups DPS and Ai's RNG. In general remember to dodge as much as you can to keep ", React.createElement(Trait, {
    name: "Light on your Feet",
    id: "1912"
  }), " active and use your mobility skills to keep attacking Ai as much as you can. ", React.createElement(Skill, {
    name: "Sun Spirit",
    id: "12498"
  }), " will probably die a couple of times so make sure you re-summon it as soon as you can, and call it to you when you move. Finally CC Ai when you can, but also remember to use ", React.createElement(Skill, {
    name: "Concussion Shot",
    id: "12508"
  }), " and ", React.createElement(Skill, {
    id: "46432",
    id: "46432"
  }), " to keep up ", React.createElement(Trait, {
    name: "Twice as Vicious",
    id: "2127"
  }), ".")), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Follow Ai to the middle of the arena summoning ", React.createElement(Skill, {
    name: "Sun Spirit",
    id: "12498"
  }), " as soon as you can and camp Shortbow until the first Sorrow spawns. Use ", React.createElement(Skill, {
    name: "Concussion Shot",
    id: "12508"
  }), " to CC."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Use ", React.createElement(Skill, {
    name: "One Wolf Pack",
    id: "45717"
  }), " and swap to Dagger/Torch and use ", React.createElement(Skill, {
    name: "Bonfire",
    id: "12504"
  }), ", ", React.createElement(Skill, {
    name: "Double Arc",
    id: "43536"
  }), ", ", React.createElement(Skill, {
    name: "Throw Torch",
    id: "12635"
  }), " and your ", React.createElement(Skill, {
    id: "42944",
    id: "42944"
  }), " skills."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "At this point Ai will either stay in the middle or dash to the side. If Ai dashes be prepared to follow calling ", React.createElement(Skill, {
    name: "Sun Spirit",
    id: "12498"
  }), " when you can."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Quickly swap to Shortbow and use ", React.createElement(Skill, {
    name: "Quick Shot",
    id: "12517"
  }), " to dodge back as Fears will be closing in, and continue with your rotation camping Shortbow until the Split mechanic happens."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "After the split mechanic re-summon ", React.createElement(Skill, {
    name: "Sun Spirit",
    id: "12498"
  }), " if it has died and swap to Dagger/Torch continuing with your rotation until Ai phases at 33%."), "\n"), "\n"), "\n", React.createElement(_components.h3, null, React.createElement(_components.em, null, "33% - 0%")), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "At this point aim to be on Shortbow so you can CC a Sorrow with ", React.createElement(Skill, {
    name: "Concussion Shot",
    id: "12508"
  }), " and if you need ", React.createElement(Skill, {
    id: "46432",
    id: "46432"
  }), "."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Use ", React.createElement(Skill, {
    name: "One Wolf Pack",
    id: "45717"
  }), " and swap to Dagger/Torch and use ", React.createElement(Skill, {
    name: "Bonfire",
    id: "12504"
  }), ", ", React.createElement(Skill, {
    name: "Double Arc",
    id: "43536"
  }), ", ", React.createElement(Skill, {
    name: "Throw Torch",
    id: "12635"
  }), " and your ", React.createElement(Skill, {
    id: "42944",
    id: "42944"
  }), " skills."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "At this point Ai will either stay in the middle or dash to the side. If Ai dashes be prepared to follow calling ", React.createElement(Skill, {
    name: "Sun Spirit",
    id: "12498"
  }), " when you can."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Quickly swap to Shortbow and use ", React.createElement(Skill, {
    name: "Quick Shot",
    id: "12517"
  }), " to dodge back as Fears will be closing in, and continue with your rotation camping Shortbow until the Split mechanic happens."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "After the split mechanic re-summon ", React.createElement(Skill, {
    name: "Sun Spirit",
    id: "12498"
  }), " if it has died and swap to Dagger/Torch continuing with your rotation until Ai dies."), "\n"), "\n"));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
